<template>
  <div class="urban-manage-news">
    <div class="content-wrapper" ref="content">
      <!-- <scroll-list
        class="card-box"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        :data="data"
        :getData="getData">
        <div class="news-wrapper border-bottom-1px" v-for="item in data" :key="item.idCcNewsManage" @click="toNewsDetail(item)">
          <div class="news-info-wrapper">
            <h4>{{item.newsTitle}}</h4>
            <div class="img-list" v-if="item.coverUrlArr.length > 1">
              <img v-for="(img, index) in item.coverUrlArr" v-lazy="img" :key="index" alt="">
            </div>
            <p><span>{{item.publishTime}}</span><span>{{item.newsPromulgator}}</span></p>
          </div>
          <img v-if="item.coverUrlArr.length === 1" v-lazy="item.coverUrlArr[0]" alt="">
        </div>
      </scroll-list> -->
      <div class="card-box">
        <ListGroup
          ref="List"
          :config="config"
        ></ListGroup>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
import ListGroup from '@/components/list-group/index.js'
import { getTime } from '@/utils/utils.js'
const { getCgNewsList } = API

export default {
  name: 'urbanManageNews',
  data () {
    return {
      data: [], // 分页数据
      pageSize: 10,
      config: {
        getList: (params) => this.getData(params, ''),
        render: (item) => this.renderItem(item)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.contentScrollTop = this.$refs.content.scrollTop
    next()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$refs.content.scrollTop = vm.contentScrollTop
    })
  },
  methods: {
    renderItem (item) {
      const time = getTime(new Date(item.publishTime.replace(/-/g, '/')))
      return (
        <div class="news-wrapper border-bottom-1px" key={ item.idCcNewsManage } on-click={ () => this.toNewsDetail(item) }>
          <div class="news-info-wrapper">
            <h4>{ item.newsTitle }</h4>
            {
              item.coverUrlArr.length > 1
                ? <div class="img-list">
                  {
                    item.coverUrlArr.map(img => {
                      return <img class="img-more" v-lazy={img} key={ img } alt="" />
                    })
                  }
                </div>
                : null
            }
            <p><span>{ time }</span><span>{ item.newsPromulgator }</span></p>
          </div>
          {
            item.coverUrlArr.length === 1
              ? <img class="img-one" v-lazy={item.coverUrlArr[0]} alt="" />
              : null
          }
        </div>
      )
    },
    // 获取所有城管动态列表
    async getData (isRefresh) {
      return new Promise((resolve, reject) => {
        getCgNewsList({
          start: this.page,
          limit: this.pageSize,
          state: 3
        }).then(({ data, success, message, totalCount }) => {
          if (success) {
            resolve({
              list: data,
              total: totalCount
            })
          } else {
            throw new Error(message)
          }
        })
      })
    },
    toNewsDetail (data) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          id: data.idCcNewsManage
        }
      })
    }
  },
  components: {
    // 'scroll-list': () => import('@/components/ScrollList.vue')
    ListGroup
  }
}
</script>

<style lang="scss" scoped>
  .urban-manage-news{
    .news-wrapper{
      display: flex;
      padding-bottom: 28px;
      margin-bottom: 28px;
      .news-info-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        h4{
          font-size: 28px;
          color: #333;
          font-weight: 500;
          margin-bottom: 24px;
        }
        p{
          font-size: 24px;
          color: #999999;
          span:last-child{
            margin-left: 18px;
          }
        }
        .img-list{
          display: flex;
          margin-bottom: 14px;
          .img-more{
            width: 206px;
            height: 114px;
            &:not(:first-child){
              margin-left: 18px;
            }
          }
        }
      }

      .img-one{
        width:250px;
        height:138px;
        margin-left: 30px;
      }
    }
  }
</style>
